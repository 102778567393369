import '../App.css';
import Navbar from "../Components/Navbar";
import Footer from '../Components/Footer';

export function Contact() {
    return (
        <div className='App'>
            <Navbar/>
            <h1>This is the Contact Page</h1>
            <Footer/>
        </div>
    )
}